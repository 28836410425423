import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`In a perfect world, where our school is truly inclusive, the people of colour feel safe, represented, and treated equally. stereotypes aren’t perpetuated here. Our school population truly represents the cultures of us all, and we taught about the true cultures of various areas of the world. Regardless of background, our classrooms, filled with the song that is a diverse culture, the art, the music, the history, the struggle, the change. All of that is the song of diversity, that in a perfect world, our school would sing, and sing with passion at that.`}</p>
    <p>{`In a perfect world, our trans, gender non-conforming, and non-binary students are recognized. They are taught about themselves, and our curriculum reflects their struggles, and acknowledges the lives which they lead, as non-cisgendered people. They learn about their bodies in the same way cis-gendered people do. See themselves in the media we watch, and the material we expose ourselves to. In school, we sing an even more complex song of gender and acceptance.`}</p>
    <p>{`In a perfect world, sign language is a mandatory course, making us even more well-rounded students and allowing us to keep building connections with people who need sign language to communicate. We have elevators too, to allow everyone to get the kind of education that PSI offers, no matter their ability. People who have learning abilities are respected and taken into account when creating a curriculum that is inclusive of us all. In classrooms, the r-slur isn’t thrown around casually and we all understand the weight of words and their impact on people with abilities.`}</p>
    <p>{`In a perfect world, our school includes sessions with guidance counselors as a mandatory part of our curriculum, to make sure that throughout our hard work, our mental wellbeing isn’t lost in the chaos. More in-depth sessions are always offered and given to students that struggle with their mental health. Teachers also understand when students are dealing with mental health issues, and know how to properly address them. Our curriculum strips away taboos, stereotypes, and negative associations with mental health to create a comfortable and understanding environment for everyone at school.`}</p>
    <p>{`In a perfect world, LGBTQ+ members of our community feel safe, represented, and simply acknowledged. All of our teachers know how to address students in that community, and converse about them respectfully. We are given opportunities to reflect upon our identities, and the school makes room for growth in our identities to take place. Our curriculum purposely recognizes us and shows LGBTQ+ relationships just as much as they don’t. Teachers make conscious decisions in how they address us, and the terminology which they use, and sing our now polyphonic song of pride, and appreciation.`}</p>
    <p>{`In a perfect world, women are uplifted in school, being given the same opportunities as men. Our struggles are openly talked about, and male classmates are taught how to thoroughly respect and identify how their past perceptions may have been rude or misogynistic. We are treated with dignity and respect no matter what setting we are in and given the exact same opportunities as our male counterparts.`}</p>
    <p>{`In a perfect world, everyone is understood, heard, represented, taught about, and seen no matter their background, identity, struggles, or personal issues. Striving to be like this is the only way we can ever truly be a community each belting the song of diversity, equity, and inclusivity.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      